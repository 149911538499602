import React from "react";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";

const Modal = ({ setIsOpen }) => {
  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h2 className={styles.heading}>Nossa <strong>Política de Privacidade</strong></h2>
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className={styles.modalContent}>
          <div data-ix="show-up-element-on-scroll-3" className="w-col w-col-8 column-case-study" style={{opacity: 1,transform: "translateX(0px) translateY(0px) translateZ(0px)", transition: "opacity 900ms ease 0s, transform 900ms ease 0s"}}>
					<div className="case-study-section">
						<p className="paragraph-dark-text case-study">Sandra Regina Luiz EPP / CNPJ: 12.331.910/0001-36&nbsp;
						<br/>Endereço: Rua Carijós 275, Vila Alzira - Santo André, São Paulo - CEP:09180-000
						<br/>Email: contato@consultefacil.com.br / Telefone: 11 4457-2842</p>
					</div>
					<div className="case-study-section">
						<div className="title">Respeita a privacidade de cada visitante que acessa seus serviços.</div>
						<h3 className="h3 case-study">Nossa <strong data-new-link="true">política de privacidade</strong> visa assegurar a garantia de que, quaisquer informações relativas aos usuários, não serão fornecidas, publicadas ou comercializadas em quaisquer circunstâncias.<span className="h3-light-text"></span></h3>
						<h3 className="h3 case-study">A <strong data-new-link="true">Consulte Facil</strong> obtém informações dos usuários através de <strong data-new-link="true">Cadastro</strong> e <strong data-new-link="true">Cookies</strong>.<span className="h3-light-text"></span></h3>
					</div>
					<div className="case-study-section">
						<div className="title">cadastro</div>
						<p className="paragraph-dark-text case-study">Para usufruir dos serviços oferecidos em nosso site, você precisa se cadastrar e aceitar as condições de uso do sistema. Este cadastro é armazenado em um banco de dados protegido e sigiloso. Seu e-mail e dados pessoais não serão divulgados em hipótese alguma. Utilizaremos seu e-mail apenas para envio de mensagens automáticas geradas pelo sistema e boletins periódicos.
						<br/>O usuário cadastrado na Consulte Facil estará habilitado a rever ou alterar seu cadastro na opção (meus dados) no menu do painel do cliente.</p>
					</div>
					<div className="case-study-section">
						<div className="title">Não Divulgação das Informações</div>
						<p className="paragraph-dark-text case-study">A Consulte Facil compromete-se a não comercializar, compartilhar ou distribuir dados pessoais de usuários, tais como Nome, CPF, CNPJ, endereço eletrônico ou endereço postal a terceiros, entidades, empresas, ou sites.</p>
					</div>
					<div className="case-study-section">
						<div className="title">cookies</div>
						<p className="paragraph-dark-text case-study">A Consulte Facil coleta informações através de cookies (informações enviadas pelo servidor do Consulte Facil ao computador do usuário, para identificá-lo).
						<br/>Os cookies servem unicamente para controle interno de audiência e de navegação e jamais para controlar, identificar ou rastrear preferências do internauta, exceto quando este desrespeitar alguma regra de segurança ou exercer alguma atividade prejudicial ao bom funcionamento do site, como por exemplo tentativas de hackear o serviço.
						<br/>A aceitação dos cookies pode ser livremente alterada na configuração de seu navegador.</p>
					</div>
					<div className="case-study-section">
						<div className="title">Direito de Acesso</div>
						<p className="paragraph-dark-text case-study">O usuário tem direito de acessar e atualizar seus dados pessoais ou solicitar sua exclusão.&nbsp;
						<br/>
						Caso o usuário deseje excluir seus dados pessoais em poder da Consulte 
						Facil, deverá entrar em contato com nossa equipe através do fale 
						conosco, do email: contato@consultefacil.com.br ou do telefone (11) 
						4457-2842 e pedir o devido cancelamento. <br/>
						A solicitação do usuário será tratada de maneira imediata e adequada.&nbsp;
						<br/>Não será cobrada qualquer taxa para executar a exclusão.</p>
					</div>
					<div className="case-study-section">
						<div className="title">Segurança das Informações</div>
						<p className="paragraph-dark-text case-study">Todos os dados pessoais informados ao nosso site são armazenados em um banco de dados reservado e com acesso restrito a algumas pessoas habilitadas que são obrigadas, por contrato, a manter a confidencialidade das informações e não utilizá-las inadequadamente.
						<br/>
						<br/>Nossa política envolve os seguintes dispositivos de segurança:
						<br/>
						<br/>1) Certificado de Segurança HTTPS: trata-se da mesma certificação utilizada em home banking e garante que todas as informações do site são trafegadas na Internet de forma criptografada;
						<br/>2) Acesso por meio de senha única e exclusiva, onde nem mesmo os atendentes da empresa têm acesso a senha;
						<br/>3) Senha armazenada em banco de dados de maneira criptografada.</p>
					</div>
					<div className="case-study-section">
						<p className="paragraph-dark-text case-study">Se você tiver alguma dúvida sobre esta Política de Privacidade sinta-se a vontade para entrar em contato.
						<br/>
						<br/>Sandra Regina Luiz EPP / CNPJ: 12.331.910/0001-36&nbsp;
						<br/>Endereço: Rua Carijós 275, Vila Alzira - Santo André, São Paulo - CEP:09180-000
						<br/>Email: contato@consultefacil.com.br / Telefone: 11 4457-2842</p>
					</div>
				</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;