import React from "react";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";

const Termos = ({ setIsOpen }) => {
  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h2 className={styles.heading}>Nosso <strong>Contrato</strong></h2>
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <div className={styles.modalContent}>
          <div data-ix="show-up-element-on-scroll-3" className="w-col w-col-8 column-case-study" style={{opacity: 1,transform: "translateX(0px) translateY(0px) translateZ(0px)", transition: "opacity 900ms ease 0s, transform 900ms ease 0s"}}>
          <div className="case-study-section">
            <div className="title">CONTRATO DE PRESTAÇÃO DE SERVIÇOS</div>
            <h3 className="h3 case-study">CONTRATADA:<span className="h3-light-text"></span></h3>
            <p className="paragraph-dark-text case-study">Sandra Regina Luiz EPP / CNPJ: 12.331.910/0001-36&nbsp;
              <br/>Endereço: Rua Carijós 275, Vila Alzira - Santo André, São Paulo - CEP:09180-000
              <br/>Email: contato@consultefacil.com.br / Telefone: 11 4457-2842</p>
          </div>
          <div className="case-study-section">
            <h3 className="h3 case-study">CONTRATANTE:</h3>
            <p className="paragraph-dark-text case-study">Cliente identificado no site. Resolvem firmar o presente contrato de prestação de serviços, que será regido de acordo com as seguintes cláusulas e condições.</p>
          </div>
          <div id="credits" className="case-study-section">
            <div className="title">I - DO OBJETO</div>
            <p className="paragraph-dark-text case-study"><strong data-new-link="true">1ª</strong> A CONTRATADA colocará à disposição do CONTRATANTE serviços de informações cadastrais de origens seguras e de conhecimento público, não privilegiadas, conforme relacionadas na página inicial do site WWW.CONSULTEFACIL.COM.BR, ou seja, a CONTRATADA não possui "banco de dados" próprio, socorrendo-se de “bancos de dados” de terceiros, incorporadas no todo ou em parte, aos serviços ofertados ao mercado para análise comparativa de crédito, respeitadas as disposições constantes neste contrato.</p>
          </div>
          <div id="credits" className="case-study-section">
            <div className="title">II – DO PREÇO</div>
            <p className="paragraph-dark-text case-study"><strong data-new-link="true">2ª</strong> Pelos serviços de informação disponibilizados pela CONTRATADA, independente da data da compra do crédito, será cobrado de modo pré-pago no site WWW.CONSULTEFACIL.COM.BR vigente no dia da consulta, valor que será imediatamente abatido no saldo existente na conta do CONTRATANTE. Valor minimo para ser Inserido é de R$ 16,00. Os valores de compras estão disponibilizados da seguinte maneira R$16,00, R$ 20,00, R$ 25,00, R$ 30,00, R$ 40,00, R$ 50,00, R$ 60,00, R$ 70,00, R$ 80,00, R$ 90,00, R$ 100,00 e R$ 200,00 reais, portanto todo saldo remanescente poderá ser utilizados em futuras consultas. Não haverá cobrança de mensalidade. Parágrafo único: Caso o saldo existente não seja suficiente, a consulta não será concluída.
            </p>
          </div>
          <div id="credits" className="case-study-section">
            <div className="title">III - DA FORMA DE PAGAMENTO</div>
            <p className="paragraph-dark-text case-study"><strong data-new-link="true">3ª</strong> Para compra de créditos para utilização de consultas no sistema, o CONTRATANTE poderá se valer das formas constantes no site, ou seja, através de boleto bancário, transferência bancária, cartões de crédito e outros.</p>
          </div>
          <div id="credits" className="case-study-section">
            <div className="title">IV - DO PRAZO</div>
            <p className="paragraph-dark-text case-study"><strong data-new-link="true">4ª</strong> Uma vez adquirido o crédito, terá sua validade indeterminada, os créditos não expiram, serão deduzidos conforme a realização de consultas. Sem direito a reembolso, podendo, no entanto, ser complementado para efetuar novas consultas.&nbsp;
              <br/>
              <br/><strong data-new-link="true">5ª</strong> Este contrato é celebrado por prazo indeterminado, porém, caso a CONTRATADA tenha necessidade técnica de paralisar as consultas por prazo superior a 30 (trinta) dias, poderá o CONTRATANTE solicitar o reembolso de créditos existentes.</p>
          </div>
          <div id="credits" className="case-study-section">
            <div className="title">V - DOS REQUISITOS COMERCIAIS</div>
            <p className="paragraph-dark-text case-study"><strong data-new-link="true">6ª</strong> A oferta dos serviços da CONTRATADA está direcionada a pessoas jurídicas, profissionais liberais autônomos que observem, sob pena de imediato cancelamento deste:a) Não tenham um faturamento anual bruto superior a R$ 10.000.000,00 (dez milhões de reais);b) Não sejam instituições financeiras, órgãos públicos federais, estaduais e municipais, autarquias ou empresas e entidades que prestem serviços de informações, de cobrança e assemelhados.</p>
          </div>
          <div id="credits" className="case-study-section">
            <div className="title">VI - DOS REQUISITOS TÉCNICOS</div>
            <p className="paragraph-dark-text case-study"><strong data-new-link="true">7ª</strong> O CONTRATANTE deverá acessar as informações disponibilizadas pela CONTRATADA por meio de recursos próprios - terminais, linhas de comunicação, modem, etc. - mediante o código e senha exclusivos fornecidos pela CONTRATADA por meios automatizados, via conexão computador a computador.Parágrafo Primeiro: A aquisição dos terminais e da linha de comunicação, assim como as demais despesas decorrentes, correrão por conta da CONTRATANTE.Parágrafo Segundo: O CONTRATANTE responsabiliza-se pelo resguardo de sua senha, não a repassando a terceiros. A senha pode ser substituída pela CONTRATADA a qualquer tempo ou através do site pelo CONTRATANTE.
              <br/>
              <br/><strong data-new-link="true">8ª</strong> O CONTRATANTE poderá adotar meios de comunicação de dados, equipamentos e periféricos eletrônicos, próprios ou de terceiros, cuja responsabilidade, custeio e manutenção ficam sob a sua exclusiva responsabilidade, bem como poderá desenvolver formas próprias de apresentação das informações relativas à prestação de serviços.</p>
          </div>
          <div id="credits" className="case-study-section">
            <div className="title">VII - DAS RESPONSABILIDADES DAS PARTES</div>
            <p className="paragraph-dark-text case-study"><strong data-new-link="true">9ª</strong> A CONTRATADA não se responsabiliza em nenhuma hipótese por riscos ou prejuízos causados por fraudes ou inadimplência, independente do resultado das consultas, já que o CONTRATANTE tem completa ciência que é o único responsável pela decisão final de conceder ou não o crédito a terceiros.Parágrafo único: A CONTRATADA responsabiliza-se pela integridade das informações que fornece tais como as recebe de suas fontes, não se responsabilizando pela veracidade, exatidão ou data de atualização.
              <br/>
              <br/><strong data-new-link="true">10ª</strong> É vedado ao CONTRATANTE, sob pena de cancelamento do acesso às informações e arcar com perdas e danos:a) Divulgar a terceiros, em nenhuma hipótese e sob qualquer forma, as informações obtidas nas consultas, ficando obrigado a manter sigilo quanto à existência e ao conteúdo das informações acessadas;b) Permitir que pessoas não credenciadas operem o sistema relativo à obtenção e à utilização de informações disponibilizadas pela CONTRATADA;c) Armazenar, divulgar e/ou reproduzir qualquer tela com dados de propriedade das fontes da CONTRATADA e/ou afiliados, tanto total como parcialmente;d) Utilizar os serviços de informação para obter informações de pessoas naturais ou jurídicas com outra finalidade que não a de prover exclusivamente a análise de credito e a realização de negócios;e) Estabelecer convênio de repasse das informações obtidas na execução do presente contrato;f) Utilizar as informações obtidas com a execução do presente instrumento para constranger ou coagir, de qualquer maneira que seja, pessoas jurídicas ou naturais;g) Contestar o pagamento junto a sua operadora de cartão de crédito, agindo assim, de má fé, sob pena de sofrer ações administrativas ou judiciais;h) Comercializar e/ou revender qualquer das informações obtidas da CONTRATADA.
              <br/>
              <br/><strong data-new-link="true">11ª</strong> O CONTRATANTE deverá indenizar, regressivamente, a CONTRATADA e/ou terceiros, por todas e quaisquer perdas e danos diretos, indiretos, incidentais ou consequências advindas, por qualquer forma que seja, administrativa ou judicial, de seus atos ou omissões, em violação da lei ou de suas obrigações contratuais, em especial descritas na cláusula 13ª, no montante da condenação, acrescido de juros de 1% (um por cento) ao mês e multa de 20% (vinte por cento), cujo valor será atualizado desde a data do desembolso, pela CONTRATADA, até do efetivo pagamento, pela CONTRATANTE, mais correção monetária pelos índices legais verificada durante o respectivo período.
              <br/>
              <br/><strong data-new-link="true">12ª</strong> O CONTRATANTE compromete-se a pautar o seu relacionamento com seus clientes em princípios éticos e morais em suas relações comerciais.</p>
          </div>
          <div id="credits" className="case-study-section">
            <div className="title">VIII - DO SIGILO</div>
            <p className="paragraph-dark-text case-study"><strong data-new-link="true">13ª</strong> As partes comprometem-se a manter sigilo sobre todas as informações comerciais ou técnicas, bem como documentação correlata, de qualquer forma, fornecidas por uma parte à outra, referentes ao cumprimento do presente contrato, inclusive as relativas aos detentores de senhas do serviço de consulta, e a não revelar tais informações, sob qualquer pretexto, salvo quando requisitadas pelos órgãos governamentais competentes e pelo Poder Judiciário.Parágrafo Único: As partes obrigam-se a obter de terceiros, que devem, por previsão legal, conhecer e receber informações oriundas deste contrato ou parte delas, o compromisso de manter a confidencialidade e fazer uso restrito de tais informações.</p>
          </div>
          <div id="credits" className="case-study-section">
            <div className="title">IX - DA NÃO-VINCULAÇÃO</div>
            <p className="paragraph-dark-text case-study"><strong data-new-link="true">14ª</strong> O presente contrato não cria nenhum vínculo societário, associativo, de representação, de agenciamento de consórcio ou assemelhados, entre as partes, arcando cada qual com as suas respectivas obrigações.</p>
          </div>
          <div id="credits" className="case-study-section">
            <div className="title">X - DO FORO</div>
            <p className="paragraph-dark-text case-study"><strong data-new-link="true">15ª</strong> Fica eleito o foro da cidade e comarca de Santo André para dirimir qualquer dúvida oriunda deste contrato, com renúncia de qualquer outro, por mais privilegiado que seja.E, por estarem assim justas e contratadas, as partes aceitam o presente instrumento.</p>
          </div>
        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Termos;